import PropTypes from "prop-types";
import "./Section.scss";
import useLazyLoadImage from "./useLazyLoadImage";

const Section = ({
  id,
  title,
  children,
  backgroundImage = "",
  isFading,
  hasOverlay = false,
  isLazy = true,
}) => {
  const [imgRef, lazyLoadedSrc] = useLazyLoadImage(backgroundImage, isLazy);

  return (
    <section id={id} className="section">
      {backgroundImage && (
        <div className="background-image">
          <img
            ref={imgRef}
            src={isLazy ? lazyLoadedSrc : backgroundImage}
            alt={`${title} Background`}
            className={isFading ? "fade" : ""}
          />
          {hasOverlay && <div className="overlay"></div>}
        </div>
      )}
      <div className="content">{children}</div>
    </section>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  isFading: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  isLazy: PropTypes.bool,
};

export default Section;
