import { useEffect, useRef, useState } from "react";

const useLazyLoadImage = (src, isLazy = true) => {
  const [isIntersecting, setIsIntersecting] = useState(!isLazy); // Skip lazy loading if not lazy
  const imgRef = useRef();

  useEffect(() => {
    if (!isLazy) return; // If lazy loading is disabled, skip observer logic

    const currentImgRef = imgRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, [isLazy]);

  return [imgRef, isIntersecting ? src : null];
};

export default useLazyLoadImage;
