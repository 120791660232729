import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import logoSide from "/imgs/logoside.png";
import { languages } from "../../constants/languages";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const changeLanguage = (lng) => {
    const pathParts = pathname.split("/").filter(Boolean);
    const currentLang = pathParts[0];
    let newPath;

    // If current path has a language prefix
    if (languages.some((l) => l.code === currentLang)) {
      // Remove current language prefix
      const routePath = pathParts.slice(1).join("/");
      newPath = lng === "en" ? `/${routePath}` : `/${lng}/${routePath}`;
    } else {
      // Current path doesn't have a language prefix
      newPath = lng === "en" ? pathname : `/${lng}${pathname}`;
    }

    // Clean up the path
    newPath = newPath.replace(/\/+/g, "/"); // Remove double slashes
    if (newPath === "") newPath = "/";

    i18n.changeLanguage(lng);
    navigate(newPath, { replace: true });
    closeMenu();
  };

  // Get current language from URL or default to 'en'
  const currentLang = pathname.split("/")[1];
  const isCurrentLangValid = languages.some((l) => l.code === currentLang);
  const effectiveCurrentLang = isCurrentLangValid ? currentLang : "en";

  const getNavPath = (path) => {
    if (effectiveCurrentLang === "en") {
      return path;
    }
    // Remove any leading slash from path for cleaner concatenation
    const cleanPath = path.replace(/^\//, "");
    return cleanPath
      ? `/${effectiveCurrentLang}/${cleanPath}`
      : `/${effectiveCurrentLang}`;
  };

  return (
    <header dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <div className="logoSide">
        <img src={logoSide} alt="logo" />
      </div>

      <nav className={isOpen ? "open" : ""}>
        <div className="navItems">
          <ul>
            <li>
              <Link to={getNavPath("/")} onClick={closeMenu}>
                {t("header.home")}
              </Link>
            </li>
            <li>
              <Link to={getNavPath("/products")} onClick={closeMenu}>
                {t("header.products")}
              </Link>
            </li>
            <li>
              <Link to={getNavPath("/training")} onClick={closeMenu}>
                {t("header.training")}
              </Link>
            </li>
            <li>
              <Link to={getNavPath("/contact")} onClick={closeMenu}>
                {t("header.contact")}
              </Link>
            </li>
            <li>
              <Link to={getNavPath("/aboutus")} onClick={closeMenu}>
                {t("header.about")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="languageOptions">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className={effectiveCurrentLang === lang.code ? "active" : ""}
            >
              {lang.name}
            </button>
          ))}
        </div>
      </nav>
      <button
        className={`hamburger ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
        title={t("header.toggleMenu")}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default Header;
