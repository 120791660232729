// App.jsx
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import Header from "./components/Header/Header";
import { languages } from "./constants/languages";
import { useTranslation } from "react-i18next";
import "./App.scss";

import Home from "./pages/Home";
const Training = lazy(() => import("./pages/Training"));
const Products = lazy(() => import("./pages/Products"));
const Contact = lazy(() => import("./pages/Contactus"));
const Aboutus = lazy(() => import("./pages/Aboutus"));
const Footer = lazy(() => import("./components/Footer/Footer"));

const LanguageWrapper = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (lang && languages.some((l) => l.code === lang)) {
      i18n.changeLanguage(lang);
    } else if (!lang) {
      i18n.changeLanguage("en");
    }
  }, [lang, i18n]);

  return <Outlet />;
};

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Header />
        <Suspense>
          <Routes>
            {/* Handle the root path */}
            <Route path="/" element={<LanguageWrapper />}>
              <Route index element={<Home />} />
              <Route path="training" element={<Training />} />
              <Route path="products" element={<Products />} />
              <Route path="contact" element={<Contact />} />
              <Route path="aboutus" element={<Aboutus />} />
            </Route>

            {/* Handle paths with language prefix */}
            <Route path="/:lang" element={<LanguageWrapper />}>
              <Route index element={<Home />} />
              <Route path="training" element={<Training />} />
              <Route path="products" element={<Products />} />
              <Route path="contact" element={<Contact />} />
              <Route path="aboutus" element={<Aboutus />} />
            </Route>

            {/* Catch all invalid paths */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </HelmetProvider>
  );
};

export default App;
